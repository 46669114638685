<template>
    <section class="p-2">
        <div class="pb-2">
            <div>
                <div class="container pt-3 justify-content-center text-center">
                    <div class="row">
                        <div class="col-sm-11">
                            <h1>COVID Screening Non-Employed CPH Staff/Students</h1>
                            <hr><br>
                            <h3>
                                This page is no longer in use.
                            </h3>
                            <br/>
                            <p>
                                For any questions please reach out to your CPH point of contact or contact:
                            </p>
                            <p>
                                Employee Health
                                <br/>
                                <b-icon icon="telephone"></b-icon>
                                <a href='tel:907-714-4000'>
                                    (907)714-4000
                                </a>
                                <br/>
                                <b-icon icon="envelope"></b-icon>
                                <a href='mailto:employeehealth@cpgh.org?subject=CPH Non-Employee Screening: Contact Employee Health'>
                                    employeehealth@cpgh.org
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </div>


    </section>
</template>

<script>
export default {
name: "NoPage"
}
</script>

<style scoped>

</style>
