<template>
    <div id="app">
        <NoPage />
    </div>
</template>

<script>
    import NoPage from './views/NoPage.vue'

    export default {
        name: 'App',
        components: {
            NoPage
        }
    }
</script>

<style>

</style>
